



















import { Component, Vue, Mixins } from "vue-property-decorator";
@Component
export default class EmailSubscriptionModal extends Vue {}
