










import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";

import { Meta } from "@sophosoft/vue-meta-decorator";

import { namespace } from "vuex-class";
const contentsModule = namespace("contents");
const mentorModule = namespace("mentors");

import { ApiRequest } from "@/store/types";

import BlogPostComponent from "@/components/BlogPostComponent.vue";
import MentorCarousel from "@/components/MentorCarousel.vue";

import { FilterObject, BlogPost, Mentor, Content } from "@/models";

import { ContentActionTypes, ContentGetterTypes } from "@/store/contents/types";
import { MentorActionTypes, MentorGetterTypes } from "@/store/mentors/types";

@Component({
  components: {
    BlogPostComponent,
    MentorCarousel,
  },
})
export default class BlogPage extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "blog page";
  }

  // Mentors
  @mentorModule.Action(MentorActionTypes.LoadFilteredMentors)
  LoadFilteredMentors!: (filter: FilterObject) => void;

  @mentorModule.Getter(MentorGetterTypes.Mentors)
  mentors!: ApiRequest<Mentor[]>;

  // Content

  @contentsModule.Action(ContentActionTypes.LoadBlogPost)
  LoadBlogPost!: (id: string) => void;

  @contentsModule.Action(ContentActionTypes.ClearBlogPost)
  ClearBlogPost!: () => void;

  @contentsModule.Getter(ContentGetterTypes.BlogPost)
  blogPost!: ApiRequest<BlogPost>;

  @Meta
  getMetaInfo() {
    if (!this.blogPost || !this.blogPost.value) {
      return { title: "Blogpage" };
    } else {
      return {
        title: `${this.blogPost.value.title} ${this.blogPost.value.title}`,
      };
    }
  }

  get id() {
    return this.$route.params.id;
  }

  get subscribeButton() {
    return this.getContentBody("blog-page-subscribe-button");
  }

  get filterObject(): FilterObject | undefined {
    if (this.blogPost.value) {
      const { industries } = this.blogPost.value;
      return {
        industries,
      };
    }
    return undefined;
  }

  @Watch("filterObject")
  filterChanged() {
    if (this.filterObject) {
      this.LoadFilteredMentors(this.filterObject);
    } else {
      this.LoadFilteredMentors({});
    }
  }

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.LoadBlogPost(this.id);
  }

  mounted() {
    if (!this.blogPost.value) {
      this.LoadBlogPost(this.id);
    }
  }

  beforeDestroy() {
    this.ClearBlogPost();
  }
}
