


































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueMarkdown from "vue-markdown";

import { BlogPost } from "@/models";
import { DateTime, Settings } from "luxon";

import EmailSubscriptionModal from "@/components/EmailSubscriptionModal.vue";

Settings.defaultLocale = "nl";

@Component({
  components: {
    VueMarkdown,
    EmailSubscriptionModal,
  },
})
export default class BlogPostComponent extends Vue {
  @Prop() blogPost!: BlogPost;
  @Prop() subscribeButtonText!: string;
  showModal = false;

  get publicationDate() {
    const d = DateTime.isDateTime(this.blogPost.publicationDate)
      ? this.blogPost.publicationDate
      : DateTime.fromISO(this.blogPost.publicationDate);
    return d.toLocaleString(DateTime.DATE_SHORT);
  }
}
