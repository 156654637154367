






















import { Component, Vue, Mixins } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ContentComponent from "./ContentComponent";

import EmailSubscriptionModal from "@/components/EmailSubscriptionModal.vue";

@Component({ components: { EmailSubscriptionModal } })
export default class BlogCardSubscribe extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "blog card subscribe";
  }
  showModal = false;
}
