




























import { Component, Vue, Prop } from "vue-property-decorator";
import { BlogPost } from "models";
import VueMarkdown from "vue-markdown";

@Component({ components: { VueMarkdown } })
export default class BlogCard extends Vue {
  @Prop() blogPost!: BlogPost;
  get label() {
    if (this.blogPost.subjects.length > 0) {
      return this.blogPost.subjects[0].name;
    }
    if (this.blogPost.industries.length > 0) {
      return this.blogPost.industries[0].name;
    }
    return "Blog";
  }
}
