




















import { Component, Vue } from "vue-property-decorator";

import Logo from "@/assets/logo.svg";
import Kennisgever from "@/assets/kennisgever.svg";

import { mixins } from "vue-class-component";
import ContentComponent from "./ContentComponent";
import VueMarkdown from "vue-markdown";

@Component({ components: { Logo, Kennisgever, VueMarkdown } })
export default class BlogCardSearch extends mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "blog search card";
  }
}
