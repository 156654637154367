






















































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";

import { namespace } from "vuex-class";
const contentsModule = namespace("contents");
const mentorModule = namespace("mentors");

import { ApiRequest } from "@/store/types";

import { ContentActionTypes, ContentGetterTypes } from "@/store/contents/types";
import { MentorActionTypes, MentorGetterTypes } from "@/store/mentors/types";

import { BlogPost, FilterOptions, FilterObject, Content } from "@/models";
import BlogCard from "@/components/BlogCard.vue";
import BlogCardSubscribe from "@/components/BlogCardSubscribe.vue";
import BlogCardSearch from "@/components/BlogCardSearch.vue";
import MentorFilter from "@/components/MentorFilter.vue";

@Component({
  components: {
    BlogCard,
    BlogCardSearch,
    BlogCardSubscribe,
    MentorFilter,
  },
})
export default class BlogIndex extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "blog overview";
  }

  // Mentors
  @mentorModule.Action(MentorActionTypes.LoadFilterOptions)
  LoadFilterOptions!: () => void;

  @mentorModule.Getter(MentorGetterTypes.FilterOptions)
  filterOptions!: ApiRequest<FilterOptions>;

  // Content
  @contentsModule.Action(ContentActionTypes.LoadBlogPosts)
  LoadBlogPosts!: () => void;

  @contentsModule.Getter(ContentGetterTypes.BlogPosts)
  posts!: ApiRequest<BlogPost[]>;

  get chunks() {
    if (!this.posts.value) {
      return [];
    }

    const chunks = [];
    for (let i = 0; i < this.posts.value.length; i += 6) {
      chunks.push(this.posts.value.slice(i, i + 6));
    }

    return chunks;
  }

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch(): Promise<any> {
    // return the Promise from the action
    // so that the component waits before rendering
    return Promise.all([this.LoadFilterOptions(), this.LoadBlogPosts()]);
  }

  mounted() {
    if (!this.filterOptions.value) {
      this.LoadFilterOptions();
    }
    if (!this.posts.value) {
      this.LoadBlogPosts();
    }
  }
}
